import { map, converge, assoc, zipObj, prop } from 'ramda'
import { QueryDocumentSnapshot } from '@firebase/firestore-types'

export const dataWithIds: <Data extends { id: string }>(
  docs: QueryDocumentSnapshot[],
) => Data[] = map<QueryDocumentSnapshot, any>(doc =>
  assoc('id', doc.id, doc.data()),
)

export const listToMap = (propName: string) =>
  converge(zipObj, [map(prop(propName)), val => val])
