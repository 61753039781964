import { compose, prop } from 'ramda'
import { User } from '../types'
import { Store } from '../../store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  initialized: boolean
  me: null | User
}
const initialState: State = { initialized: false, me: null }

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initialized: state => {
      state.initialized = true
    },
    confirmedAuth: (state, action: PayloadAction<null | User>) => {
      state.me = action.payload
    },
  },
})

export const reducer = slice.reducer
export const initialized = slice.actions.initialized
export const confirmedAuth = slice.actions.confirmedAuth

const stateSelector: (store: Store) => State = prop('auth')
export const initializedSelector: (store: Store) => boolean = compose(
  prop('initialized'),
  stateSelector,
)
export const meSelector: (store: Store) => null | User = compose(
  prop('me'),
  stateSelector,
)
export const isAdminSelector: (store: Store) => boolean = compose(
  Boolean,
  prop('admin') as any,
  meSelector,
)
export const isConfirmedSelector: (store: Store) => boolean = compose(
  Boolean,
  prop('confirmed') as any,
  meSelector,
)
