import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from './localizations/en.json'
import nl from './localizations/nl.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'nl',
    debug: true,
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false,
      format: function(value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase()
        if (format === 'lowercase') return value.toLowerCase()
        return value
      },
    },
    resources: {
      en: { translation: en },
      nl: { translation: nl },
    },
  })
