/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ReactNode, useState, useRef } from 'react'
import { not } from 'ramda'
import { useSpring, animated } from 'react-spring'
import { Flex, FlexButton, useMeasure } from 'vr-horizon-ui'

const Animated = Flex.withComponent(animated.div)

type Props = {
  header: ReactNode
  children: ReactNode
}
export const PopOut = ({ header, children }: Props) => {
  const [isOpen, setOpen] = useState(false)

  const contentRef = useRef<null | HTMLDivElement>(null)
  const measurement = useMeasure(contentRef)
  const spring = useSpring({
    height: isOpen && measurement ? measurement.height : 0,
    overflow: 'hidden',
  })

  return (
    <Flex css={{ position: 'relative' }} flexDirection="column">
      <FlexButton
        css={{ padding: 0 }}
        outlineColor="transparent"
        bg="transparent"
        onClick={() => setOpen(not)}>
        {header}
      </FlexButton>
      <Animated
        css={{ position: 'absolute', top: 32, left: 0 }}
        onClick={() => setOpen(false)}
        style={spring}
        flexDirection="column">
        <Flex ref={contentRef} flexDirection="column" flexShrink={0}>
          {children}
        </Flex>
      </Animated>
    </Flex>
  )
}
