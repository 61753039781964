export const createWindowResizeEnd = function() {
  let resizeEnd: undefined | NodeJS.Timeout
  window.addEventListener('resize', function() {
    resizeEnd && clearTimeout(resizeEnd)
    resizeEnd = setTimeout(function() {
      // option 1
      var evt = new Event('resize-end')
      window.dispatchEvent(evt)
      // option 2: old-fashioned
      /*var evt = document.createEvent('Event');
      evt.initEvent('resize-end', true, true);
      window.dispatchEvent(evt);*/
    }, 100)
  })
}
