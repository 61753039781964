import { get } from '../../services/api'
import { assoc } from 'ramda'
import { API_URL, ACCOUNTS_URL } from '../../config'
import firebase from 'firebase/app'
import { confirmedAuth, initialized, isAdminSelector } from '.'
import { User } from '../types'
import { Dispatch } from 'redux'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchProjects, selectProject } from '../../Projects/redux/thunks'
import { RootState } from '../../store'
import {
  selectedProjectIdSelector,
  projectsSelector,
} from '../../Projects/redux'

export const initialize = createAsyncThunk<void, void, { state: RootState }>(
  'Auth/INITIALIZE',
  async (payload, { dispatch, getState }) => {
    await dispatch(initializeAuthentication())
    await dispatch(fetchProjects())

    const selectedProject = selectedProjectIdSelector(getState())
    const isAdmin = isAdminSelector(getState())

    const projects = projectsSelector(getState())
    const autoSelect =
      !selectedProject ||
      (selectedProject === 'ALL' && !isAdmin) ||
      !projects.some(p => p.id === selectedProject)

    if (autoSelect) {
      dispatch(selectProject(isAdmin ? 'ALL' : projects[0]?.id))
    }

    dispatch(initialized())
  },
)

const authChangePromise = (): Promise<null | firebase.User> =>
  new Promise(resolve => {
    const auth = firebase.auth()
    let sub: ReturnType<typeof auth.onAuthStateChanged>
    const cb = (user: firebase.User | null) => {
      sub()
      resolve(user)
    }
    sub = auth.onAuthStateChanged(cb)
  })

export const initializeAuthentication = () => async (
  dispatch: Dispatch<any>,
) => {
  let endAction
  try {
    let user: firebase.User | null = await authChangePromise()

    if (!user) {
      const {
        data: { token },
      } = await get(`${API_URL}/auth/authStatus`)
      if (!token) throw new Error('No token obtained. User not logged in.')

      const result = await firebase.auth().signInWithCustomToken(token)

      if (!result.user)
        throw new Error('No credentials obtained. Token not valid.')

      user = result.user
    }

    const snapshot = await firebase
      .firestore()
      .collection('users')
      .doc(user.uid)
      .get()

    endAction = dispatch(
      confirmedAuth(assoc('id', user.uid, snapshot.data() as User)),
    )
  } catch (e) {
    console.log(e)
    dispatch(logout())
  }

  return endAction
}

export const logout = () => async () => {
  await firebase.auth().signOut()
  await get(`${API_URL}/auth/signOut`)
  window.location.href = ACCOUNTS_URL
}
