import { Task } from '../types'
import { createSlice, createAction } from '@reduxjs/toolkit'
import {
  compose,
  prepend,
  prop,
  assoc,
  findIndex,
  propEq,
  update,
  find,
  remove,
  path,
} from 'ramda'
import { RootState } from '../../store'
import { addTask, editTask, deleteTask, fetchPageThunk } from './thunks'

export const setMode = createAction<'open' | 'closed', 'tasks/SET_MODE'>(
  'tasks/SET_MODE',
)

type State = {
  mode: 'open' | 'closed'
  hasMore: boolean
  page: number
  size: number
  search: null | string
  loading: boolean
  tasks: Task[]
  modals: {
    selectedTaskId: null | string
    selectedStep: null | { taskId: string; stepName: string }
    taskToDelete: null | string
  }
}
const initialState: State = {
  mode: 'open' as 'open',
  hasMore: true,
  page: 0,
  size: 50,
  search: null,
  tasks: [],
  loading: false,
  modals: {
    selectedTaskId: null,
    selectedStep: null,
    taskToDelete: null,
  },
}

const slice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    selectTask: (state, action) => {
      state.modals.selectedTaskId = action.payload
    },
    selectStep: (state, action) => {
      state.modals.selectedStep = action.payload
    },
    requestTaskDelete: (state, action) => {
      state.modals.taskToDelete = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(setMode, (state, action) => {
        state.mode = action.payload
      })
      .addCase(fetchPageThunk.pending, (state, action) => {
        state.loading = true
        state.tasks = []
        state.page = action.meta.arg.page
        state.size = action.meta.arg.size
        state.search = action.meta.arg.search
      })
      .addCase(fetchPageThunk.fulfilled, (state, action) => {
        state.loading = false
        state.page = action.payload.page
        state.size = action.payload.size
        state.search = action.payload.search
        state.hasMore = action.payload.hasMore
        state.tasks = action.payload.tasks
      })
      .addCase(addTask.fulfilled, (state, action) =>
        action.payload
          ? assoc('tasks', prepend(action.payload, state.tasks), state)
          : state,
      )
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.tasks = state.tasks.filter(task => task.id !== action.payload)
        state.modals.taskToDelete = null
      })
      .addCase(editTask.fulfilled, (state, action) => {
        const index = findIndex(propEq('id', action.payload.id), state.tasks)
        return assoc(
          'tasks',
          state.mode === 'open' && action.payload.finishedAt
            ? remove(index, 1, state.tasks)
            : update(index, action.payload, state.tasks),
          state,
        )
      })
  },
})

export const reducer = slice.reducer
export const selectTask = slice.actions.selectTask
export const selectStep = slice.actions.selectStep
export const requestTaskDelete = slice.actions.requestTaskDelete

const stateSelector: (store: RootState) => State = prop('tasks')
export const modeSelector: (store: RootState) => 'open' | 'closed' = compose(
  prop('mode'),
  stateSelector,
)
export const hasMoreSelector: (store: RootState) => boolean = compose(
  prop('hasMore'),
  stateSelector,
)
export const searchSelector: (store: RootState) => null | string = compose(
  prop('search'),
  stateSelector,
)
export const sizeSelector: (store: RootState) => number = compose(
  prop('size'),
  stateSelector,
)
export const pageSelector: (store: RootState) => number = compose(
  prop('page'),
  stateSelector,
)
export const loadingSelector: (store: RootState) => boolean = compose(
  prop('loading'),
  stateSelector,
)
export const tasksSelector: (store: RootState) => Task[] = compose(
  prop('tasks'),
  stateSelector,
)
export const taskForIdSelector: (
  store: RootState,
  id: string,
) => undefined | Task = (store, id) =>
  compose(find(propEq('id', id)), tasksSelector)(store)

export const selectedTaskIdSelector: (
  store: RootState,
) => null | undefined | string = compose(
  path<string | null>(['modals', 'selectedTaskId']),
  stateSelector,
)

export const selectedTaskSelector: (
  store: RootState,
) => null | Task = store => {
  const id = selectedTaskIdSelector(store)
  if (!id) return null
  return taskForIdSelector(store, id) || null
}

export const selectedStepSelector: (
  store: RootState,
) => null | undefined | { taskId: string; stepName: string } = compose(
  path(['modals', 'selectedStep']),
  stateSelector,
)

export const taskToDeleteSelector: (
  store: RootState,
) => null | undefined | string = compose(
  path(['modals', 'taskToDelete']),
  stateSelector,
)
