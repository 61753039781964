import styled from '@emotion/styled'

const heightRatio = 100 / 177
const widthRatio = 171 / 177
const borderRatio = 20 / 177
const borderRadiusRatio = 4 / 177
const eyeBorderRatio = 12 / 177

export const Logo = styled('div')<{ size?: number }>(({ size = 177 }) => ({
  height: size * heightRatio,
  width: size * widthRatio,
  border: `${size * borderRatio}px white solid`,
  borderRadius: size * borderRadiusRatio,
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  ':before, :after': {
    content: '" "',
    borderRadius: size * eyeBorderRatio,
    border: `${size * eyeBorderRatio}px white solid`,
  },
}))
