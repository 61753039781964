import firebase from 'firebase/app'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { assoc } from 'ramda'
import { Project } from '../types'
import { RootState } from '../../store'
import { dataWithIds } from '../../Utils/data'
import { meSelector } from '../../Auth/redux'

export const fetchProjects = createAsyncThunk<
  Project[],
  void,
  { state: RootState }
>('projects/FETCH', async (_, { getState }) => {
  const me = meSelector(getState())
  if (!me) return []
  const query = firebase.firestore().collection('projects')

  const snapshot = await (me.admin
    ? query.get()
    : query.where(`roles.${me.id}`, '>', '').get())

  return dataWithIds<Project>(snapshot.docs)
})

export const selectProject = createAsyncThunk(
  'projects/SELECT',
  (projectId: string) => {
    localStorage.setItem('selectedProject', projectId)
    return projectId
  },
)

export const addProject = createAsyncThunk(
  'projects/ADD_PROJECT',
  async (project: Project) => {
    const collection = firebase.firestore().collection('projects')
    const ref = await collection.add(project)
    const snapshot = await ref.get()
    return assoc('id', snapshot.id, snapshot.data()) as Project
  },
)
export const editProject = createAsyncThunk(
  'projects/EDIT_PROJECT',
  async (project: Project) => {
    const collection = firebase.firestore().collection('projects')
    const ref = collection.doc(project.id)
    await ref.set(project)
    const snapshot = await ref.get()
    return assoc('id', snapshot.id, snapshot.data()) as Project
  },
)
