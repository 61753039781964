/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ComponentPropsWithRef, useState, ReactNode, Fragment } from 'react'
import styled, { StyledComponent } from '@emotion/styled'
import { transparentize } from 'polished'
import {
  Flex,
  getColor,
  FlexButton,
  Text,
  Theme,
  Card,
  useMatchingBreakpoint,
  Icon,
} from 'vr-horizon-ui'
import { ReactComponent as MenuIcon } from './icons/menu.svg'
import { animated, useSpring } from 'react-spring'
import { useSelector, useDispatch } from 'react-redux'
import { projectsSelector, selectedProjectSelector } from '../Projects/redux'
import { Logo } from './Logo'
import { selectProject } from '../Projects/redux/thunks'
import { Link } from 'react-navi'
import { FontIcon } from './FontIcon'
import { logout } from '../Auth/redux/thunks'
import { isAdminSelector } from '../Auth/redux'
import { FlexboxProps, SpaceProps } from 'styled-system'
import { not } from 'ramda'
import { useTranslation } from 'react-i18next'
import { PopOut } from './PopOut'

const BASE_WIDTH = 240

const FlexLink: StyledComponent<
  ComponentPropsWithRef<typeof Link>,
  FlexboxProps & SpaceProps,
  Theme
> = styled(Flex.withComponent(Link))({
  textDecoration: 'none',
  textTransform: 'none',
})
const Menu = styled(Flex.withComponent(animated.div))(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  padding: '0 2px',
  background: `linear-gradient(45deg, ${getColor(
    'primaries.2',
    theme,
  )}, ${getColor('primaries.3', theme)})`,
  flexDirection: 'column',
  boxShadow: `${transparentize(0.7, getColor('darks.0', theme))} 0px 5px 13px`,
}))
const TopMenu = styled(Flex)(
  {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: 80,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  ({ theme }) => ({
    boxShadow: `${transparentize(
      0.7,
      getColor('darks.0', theme),
    )} 0px 5px 13px`,
  }),
)

type Props = { children: ReactNode }
export const SideMenu = ({ children }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isDesktop = useMatchingBreakpoint('lg')
  const [open, setOpen] = useState<boolean>(false)
  const projects = useSelector(projectsSelector)
  const isAdmin = useSelector(isAdminSelector)
  const selectedProject = useSelector(selectedProjectSelector)
  const spring = useSpring({
    width: BASE_WIDTH,
    transform: `translate(${isDesktop || open ? '0%' : '-100%'})`,
  })
  return (
    <Flex
      pl={isDesktop ? BASE_WIDTH : 0}
      pt={!isDesktop ? 80 : 0}
      height="100vh"
      width="100vw"
      flexShrink={0}>
      {children}
      <Menu style={spring}>
        <Flex py={4} justifyContent="center">
          {isDesktop && <Logo size={96} />}
        </Flex>

        <PopOut
          header={
            <Flex py={1} my={1}>
              <Text
                fontWeight="bold"
                color="lights.4"
                fontSize={16}
                textAlign="left"
                css={{
                  whiteSpace: 'nowrap',
                  textWrap: 'ellipsis',
                  overflow: 'hidden',
                }}>
                &nbsp; &nbsp;
                {selectedProject ? selectedProject.name : 'No project selected'}
              </Text>
            </Flex>
          }>
          <Card
            style={{ zIndex: 1 }}
            border
            shadow
            flexDirection="column"
            bg="lights.2"
            mx={2}
            mb={2}>
            {isAdmin && (
              <FlexButton
                bg="transparent"
                py={2}
                px={2}
                onClick={() => dispatch(selectProject('ALL'))}>
                <Text color="darks.2">All</Text>
              </FlexButton>
            )}

            {projects.map(project => (
              <FlexButton
                bg="transparent"
                py={2}
                px={2}
                onClick={() => dispatch(selectProject(project.id))}>
                <Text color="darks.2">{project.name}</Text>
              </FlexButton>
            ))}
          </Card>
        </PopOut>

        <Flex flexDirection="column" py={2}>
          <FlexLink href="/" py={1} px={2}>
            <Text fontSize={16} color="lights.4" fontWeight="bold">
              <FontIcon icon="list" size={20} />
              &nbsp; &nbsp; {t('navigation.overview')}
            </Text>
          </FlexLink>

          {!isAdmin && (
            <FlexLink href="/tasks/add" py={1} px={2}>
              <Text fontSize={16} color="lights.4" fontWeight="bold">
                <FontIcon icon="plus" size={20} />
                &nbsp; &nbsp; {t('navigation.create-task')}
              </Text>
            </FlexLink>
          )}

          {isAdmin && (
            <Fragment>
              <PopOut
                header={
                  <Flex px={2} py={1}>
                    <Text fontSize={16} color="lights.4" fontWeight="bold">
                      <FontIcon icon="plus" size={20} />
                      &nbsp; &nbsp; {t('navigation.create')}
                    </Text>
                  </Flex>
                }>
                <Card
                  border
                  shadow
                  flexDirection="column"
                  bg="lights.2"
                  mx={2}
                  mb={2}>
                  <FlexLink href="/tasks/add" py={2} px={2}>
                    <Text fontSize={16} color="darks.2">
                      {t('navigation.create-task')}
                    </Text>
                  </FlexLink>
                  <FlexLink href="/projects/add" py={2} px={2}>
                    <Text fontSize={16} color="darks.2">
                      {t('navigation.create-project')}
                    </Text>
                  </FlexLink>
                </Card>
              </PopOut>
              {selectedProject && selectedProject.id !== 'ALL' && (
                <FlexLink
                  href={`/projects/${selectedProject.id}/edit`}
                  py={1}
                  px={2}>
                  <Text fontSize={16} color="lights.4" fontWeight="bold">
                    <FontIcon icon="edit" size={20} />
                    &nbsp; &nbsp; {t('navigation.edit-project')}
                  </Text>
                </FlexLink>
              )}
            </Fragment>
          )}
        </Flex>

        <Flex flex={1}></Flex>

        <Flex flexDirection="column" py={2}>
          <FlexButton
            bg="transparent"
            py={2}
            px={2}
            onClick={() => dispatch(logout())}>
            <Text fontSize={16} color="lights.4" fontWeight="bold">
              <FontIcon icon="log-out" size={20} />
              &nbsp; &nbsp; {t('navigation.logout')}
            </Text>
          </FlexButton>
        </Flex>
      </Menu>
      {isDesktop || (
        <TopMenu bg="primaries.3" px={3}>
          <FlexButton bg="transparent" onClick={() => setOpen(not)}>
            <Icon icon={MenuIcon} color="lights.4" />
          </FlexButton>
          <Logo size={64} />
        </TopMenu>
      )}
    </Flex>
  )
}
