import { Dispatch } from 'redux'
import {
  compose,
  filter,
  any,
  equals,
  map,
  assoc,
  assocPath,
  dissoc,
  path,
} from 'ramda'
import firebase from 'firebase/app'
import { Task, TaskData, TaskStep } from '../types'
import { Store, RootState } from '../../store'
import uuid from 'uuid/v4'
import { stepListToMap } from '../utils'
import { ProjectStep } from '../../Projects/types'
import {
  taskForIdSelector,
  modeSelector,
  pageSelector,
  searchSelector,
  sizeSelector,
} from '.'
import {
  selectedProjectIdSelector,
  stepsForProjectIdSelector,
} from '../../Projects/redux'
import { createAsyncThunk, ThunkAction } from '@reduxjs/toolkit'
import { get } from '../../services/api'
import { stringify } from 'querystring'
import { API_URL } from '../../config'

const createTask = async ({
  attachmentFile = null,
  optionalSteps = [],
  projectId,
  projectSteps,
  taskData,
}: {
  taskData: TaskData
  attachmentFile?: null | File
  projectId: string
  projectSteps: ProjectStep[]
  optionalSteps?: string[]
}) => {
  const currentUser = firebase.auth().currentUser

  if (!currentUser) throw new Error('Not logged in')
  if (!projectId) throw new Error('No project selected')
  const { uid } = currentUser

  let uploadedFileId
  if (attachmentFile) {
    const fileId = uuid()
    await firebase
      .storage()
      .ref(`task-attachments/${fileId}`)
      .put(attachmentFile)
    uploadedFileId = fileId
  }

  const steps: TaskStep[] = compose(
    map(({ name, category }) => ({
      name,
      note: null,
      finishedAt: null,
      category: category || null,
    })),
    filter<ProjectStep>(
      step =>
        (step.default && !step.editable) ||
        any(equals(step.name), optionalSteps),
    ) as (steps: ProjectStep[]) => ProjectStep[],
  )(projectSteps)

  const ref = await firebase
    .firestore()
    .collection('tasks')
    .add({
      authUid: uid,
      note: '',
      project: projectId,
      finishedAt: null,
      data: { ...taskData, attachment: uploadedFileId || '' },
      createdAt: Date.now(),
      steps: stepListToMap(steps),
    })
  const doc = await ref.get()

  return assoc('id', doc.id, doc.data()) as Task
}

const updateTask = async (task: Task): Promise<Task> => {
  await firebase
    .firestore()
    .collection('tasks')
    .doc(task.id)
    .set(task)
  const snapshot = await firebase
    .firestore()
    .collection('tasks')
    .doc(task.id)
    .get()

  const data = snapshot.data() as Task

  return assoc('id', snapshot.id, data as Task)
}

export const fetchPageThunk = createAsyncThunk<
  {
    tasks: Task[]
    page: number
    search: null | string
    size: number
    hasMore: boolean
  },
  { page: number; size: number; search: null | string },
  { state: RootState }
>(
  'tasks/FETCH_TASK_PAGE',
  async ({ page = 0, size, search = null }, { getState }) => {
    const currentProject = selectedProjectIdSelector(getState())
    const currentMode = modeSelector(getState())
    const oldState = getState().tasks

    try {
      const token = await firebase.auth().currentUser?.getIdToken(true)
      const params = {
        offset: page * size,
        length: size,
        project: currentProject === 'ALL' ? '' : currentProject,
        status: currentMode,
        search: search || '',
      }
      const { data } = await get<Task[]>(
        `${API_URL}/vrHorizonApp/tasks?${stringify(params)}`,
        null,
        {
          Authorization: token || '',
        },
      )

      if (!data.length) {
        return {
          tasks: oldState.tasks,
          page: oldState.page,
          size: oldState.size,
          search: oldState.search,
          hasMore: false,
        }
      }

      return { tasks: data, size, page, search, hasMore: data.length === size }
    } catch (e) {
      console.log(e)
      return { tasks: [], size, page, search, hasMore: false }
    }
  },
)

export const fetchPage = ({
  page,
}: {
  page: number
}): ThunkAction<ReturnType<typeof fetchPageThunk>, RootState, any, any> => (
  dispatch,
  getState,
) => {
  const search = searchSelector(getState())
  const size = sizeSelector(getState())
  return dispatch(fetchPageThunk({ search, page, size }))
}
export const fetchWithSize = ({
  size,
}: {
  size: number
}): ThunkAction<ReturnType<typeof fetchPageThunk>, RootState, any, any> => (
  dispatch,
  getState,
) => {
  const search = searchSelector(getState())
  return dispatch(fetchPageThunk({ search, page: 0, size }))
}
export const fetchWithSearch = ({
  search,
}: {
  search: null | string
}): ThunkAction<ReturnType<typeof fetchPageThunk>, RootState, any, any> => (
  dispatch,
  getState,
) => {
  const size = sizeSelector(getState())
  return dispatch(fetchPageThunk({ search, page: 0, size }))
}
export const fetchNextPage = (): ThunkAction<
  ReturnType<typeof fetchPageThunk>,
  RootState,
  any,
  any
> => (dispatch, getState) => {
  const page = pageSelector(getState())
  const search = searchSelector(getState())
  const size = sizeSelector(getState())
  return dispatch(fetchPageThunk({ search, page: page + 1, size }))
}
export const fetchPrevPage = (): ThunkAction<
  ReturnType<typeof fetchPageThunk>,
  RootState,
  any,
  any
> => (dispatch, getState) => {
  const page = pageSelector(getState())
  const search = searchSelector(getState())
  const size = sizeSelector(getState())
  return dispatch(fetchPageThunk({ search, page: page - 1, size }))
}

export const addTask = createAsyncThunk<
  Task | undefined,
  {
    taskData: TaskData
    attachmentFile?: null | File
    optionalSteps?: string[]
  },
  { state: any }
>(
  'tasks/ADD_TASK',
  async ({ taskData, optionalSteps, attachmentFile }, { getState }) => {
    const projectId = selectedProjectIdSelector(getState())
    if (!projectId || projectId === 'ALL') return

    const projectSteps = stepsForProjectIdSelector(getState(), projectId)
    const newTask = await createTask({
      taskData,
      attachmentFile,
      projectId,
      projectSteps,
      optionalSteps,
    })
    return newTask
  },
)

export const deleteTask = createAsyncThunk<
  string | undefined,
  string,
  { state: any }
>('tasks/DELETE_TASK', async (id, { getState }) => {
  const firestore = firebase.firestore()
  const ref = firestore.collection('tasks').doc(id)
  await ref.delete()
  return ref.id
})

export const editTask = createAsyncThunk('task/UPDATE_TASK', updateTask)

export const updateNoteForTask = (note: string, taskId: string) => (
  dispatch: Dispatch<any>,
  getState: () => Store,
) => {
  const task = taskForIdSelector(getState(), taskId)
  if (!task) throw new Error('Task not found')
  const newTask = assocPath(['data', 'note'], note, task)
  return dispatch(editTask(newTask))
}

export const updateNoteForStepForTask = (
  note: string,
  stepName: string,
  taskId: string,
) => (dispatch: Dispatch<any>, getState: () => Store) => {
  const task = taskForIdSelector(getState(), taskId)
  if (!task) throw new Error('Task not found')
  const newStep = assoc('note', note, path(['steps', stepName], task))
  const newTask = assocPath(['steps', stepName], newStep, task)
  return dispatch(editTask(newTask))
}

export const completeStepForTask = (stepName: string, taskId: string) => (
  dispatch: Dispatch<any>,
  getState: () => Store,
) => {
  const task = taskForIdSelector(getState(), taskId)
  if (!task) throw new Error('Task not found')
  const newStep = assoc(
    'finishedAt',
    Date.now(),
    path(['steps', stepName], task),
  )
  const newTask = assocPath(['steps', stepName], newStep, task)
  return dispatch(editTask(newTask))
}

export const undoCompleteStepForTask = (stepName: string, taskId: string) => (
  dispatch: Dispatch<any>,
  getState: () => Store,
) => {
  const task = taskForIdSelector(getState(), taskId)
  if (!task) throw new Error('Task not found')
  const newStep = dissoc('finishedAt', path(['steps', stepName], task))
  const newTask = assocPath(['steps', stepName], newStep, task)
  return dispatch(editTask(newTask))
}

export const completeTask = (taskId: string) => (
  dispatch: Dispatch<any>,
  getState: () => Store,
) => {
  const task = taskForIdSelector(getState(), taskId)
  if (!task) throw new Error('Task not found')
  const newTask = assoc('finishedAt', Date.now(), task)
  return dispatch(editTask(newTask))
}
