import { compose, find, propEq, prop } from 'ramda'
import { Project, ProjectStep } from '../types'
import { Store } from '../../store'
import { createSlice } from '@reduxjs/toolkit'
import { addProject, selectProject, fetchProjects, editProject } from './thunks'

type State = {
  projects: Project[]
  selected: null | string | 'ALL'
}
const initialState: State = {
  projects: [],
  selected: localStorage.getItem('selectedProject') || null,
}

const { reducer } = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.projects = action.payload
      })
      .addCase(addProject.fulfilled, (state, action) => {
        state.projects = [...state.projects, action.payload]
      })
      .addCase(editProject.fulfilled, (state, action) => {
        state.projects = state.projects.map(pr =>
          pr.id === action.payload.id ? action.payload : pr,
        )
      })
      .addCase(selectProject.fulfilled, (state, action) => {
        state.selected = action.payload
      })
  },
})

const ALL_PROJECT: Project = {
  name: 'All',
  id: 'ALL',
  roles: {},
  steps: [],
  taskDataType: 'none',
}
const stateSelector: (store: Store) => State = prop('projects')
export const projectsSelector: (store: Store) => Project[] = compose(
  prop('projects'),
  stateSelector,
)

export const projectByIdSelector: (
  store: Store,
  id: string,
) => undefined | Project = (store, id) =>
  id === 'ALL' ? ALL_PROJECT : find(propEq('id', id), projectsSelector(store))

export const stepsForProjectIdSelector: (
  store: Store,
  id: string,
) => ProjectStep[] = (store, id) =>
  prop('steps', projectByIdSelector(store, id) as Project) || []

export const selectedProjectIdSelector: (
  store: Store,
) => null | string = compose(prop('selected'), stateSelector)
export const selectedProjectSelector: (
  store: Store,
) => undefined | Project = store =>
  projectByIdSelector(store, selectedProjectIdSelector(store) as string)
export const stepsForSelectedProjectSelector: (
  store: Store,
) => ProjectStep[] = store =>
  stepsForProjectIdSelector(store, selectedProjectIdSelector(store) as string)

export { reducer }
