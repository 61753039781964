import React, { useEffect } from 'react'
import { ThemeProvider } from 'emotion-theming'
import { Router, View } from 'react-navi'
import { createTheme, Spinner, Flex } from 'vr-horizon-ui'

import { routes } from './routes'
import { Suspense } from 'react'
import { SideMenu } from './Components/SideMenu'
import './Utils/i18next'
import { useDispatch, Provider, useSelector } from 'react-redux'
import { initialize } from './Auth/redux/thunks'
import { store } from './store'
import { initializedSelector } from './Auth/redux'

type Props = {}
const App = (props: Props) => {
  const dispatch = useDispatch()

  const initialized = useSelector(initializedSelector)

  useEffect(() => {
    dispatch(initialize())
  }, [dispatch])

  return (
    <Suspense
      fallback={
        <Flex flex={1} alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      }>
      <Flex width="100vw">
        <SideMenu>{initialized && <View />}</SideMenu>
      </Flex>
    </Suspense>
  )
}

const AppWithProviders = (props: Props) => {
  return (
    <Router routes={routes}>
      <Provider store={store}>
        <ThemeProvider theme={createTheme({})}>
          <App />
        </ThemeProvider>
      </Provider>
    </Router>
  )
}

export default AppWithProviders
