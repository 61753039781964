import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { reducer as auth } from './Auth/redux'
import { reducer as projects } from './Projects/redux'
import { reducer as tasks } from './Tasks/redux'

const reducer = combineReducers({ auth, projects, tasks })

export const store = configureStore({
  reducer,
})

export type Store = ReturnType<typeof reducer>
export type RootState = ReturnType<typeof reducer>
export type AppDispatch = typeof store.dispatch
