import {
  __,
  map,
  compose,
  not,
  any,
  find,
  filter,
  propEq,
  reduce,
  prop,
} from 'ramda'
import { TaskStep, Task } from './types'
import { listToMap } from '../Utils/data'
import { ProjectStep } from '../Projects/types'

export const stepListToMap: (
  step: TaskStep[],
) => { [name: string]: TaskStep } = listToMap('name')
export const findStepByName: (
  name: string,
) => (steps: TaskStep[]) => null | TaskStep = compose<
  string,
  (step: TaskStep) => boolean,
  (steps: TaskStep[]) => null | TaskStep
>(find, propEq('name'))

export const taskFinishedAt: (
  task: Task,
  steps: ProjectStep[],
) => undefined | null | number = (task, steps) => {
  const taskSteps: TaskStep[] = compose(
    filter(Boolean) as (steps: (TaskStep | undefined)[]) => TaskStep[],
    map<ProjectStep, TaskStep | undefined>(
      compose(prop(__, task.steps) as (name: string) => TaskStep, prop('name')),
    ),
  )(steps)
  const isNotCompleted = any(
    compose(not, prop('finishedAt') as (step: TaskStep) => undefined | number),
    taskSteps,
  )

  if (isNotCompleted) return undefined

  return (
    reduce(
      (prev, { finishedAt }) =>
        !prev || (finishedAt && finishedAt > prev) ? finishedAt : prev,
      undefined as undefined | null | number,
      taskSteps,
    ) || undefined
  )
}
