import axios, { Method, AxiosPromise } from 'axios'
import { merge } from 'ramda'

axios.defaults.withCredentials = true

const defaultHeaders = {
  'Content-Type': 'application/json',
}
const withHeaders = merge(defaultHeaders)
const requestForMethod = (method: Method) => <Data extends any>(
  url: string,
  body?: any,
  headers: { [header: string]: string } = {},
): AxiosPromise<Data> =>
  axios({
    method,
    url,
    headers: withHeaders(headers),
  })

export const get = requestForMethod('GET')
export const post = requestForMethod('POST')
export const put = requestForMethod('PUT')
export const del = requestForMethod('DELETE')
