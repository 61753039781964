import React from 'react'
import { Text } from 'vr-horizon-ui'
import { ComponentPropsWithoutRef } from 'react'

const I = Text.withComponent('i')

export const FontIcon = ({
  icon,
  size,
  ...props
}: { icon: string; size?: number } & ComponentPropsWithoutRef<typeof I>) => (
  <I
    color="inherit"
    {...props}
    fontSize={size}
    className={`feather icon-${icon}`}
  />
)
