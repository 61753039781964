import { mount, lazy } from 'navi'

export const routes = mount({
  '/': lazy(() => import('./Tasks/TasksOverview')),
  '/projects/add': lazy(() => import('./Projects/CreateProject')),
  '/projects/:id/edit': lazy(() => import('./Projects/EditProject')),
  '/add': lazy(() => import('./Tasks/CreateTask')),
  '/tasks/add': lazy(() => import('./Tasks/CreateTask')),
  '/not-confirmed': lazy(() => import('./Auth/NotConfirmed')),
})
